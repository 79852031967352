<template>
  <div class="vx-row">
    <vx-card>
      <h5 style="margin-bottom:9px">Devoluciones</h5>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">
        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  reverses.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : reverses.length
                }}
                de {{reverses.length}}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
              <p style="margin-top:-60px; margin-right:-40px; font-size:10px"> Desde</p>
              <date-picker :language="languages.es" v-model="from" :first-day-of-week="1" label="Desde" placeholder="Desde" style="margin-right:-10px"></date-picker>
              <p style="margin-top: -60px; margin-right:-40px; font-size:10px"> Hasta</p>
              <date-picker :language="languages.es" v-model="to" type="datetime" label="Hasta" placeholder="Hasta" style="margin-right:-10px"></date-picker>
              <vs-input
                  class="mb-4 md:mb-0 mr-4"
                  v-model="searchQuery"
                  @input="updateSearchQuery"
                  placeholder="Buscar..."/>
              <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
          </div>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="reverses"
        @first-data-rendered="onFirstDataRendered"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>
      <ag-grid-vue style="height: 60px; flex: none;"
        class="ag-theme-material w-100 ag-grid-table total"
        :gridOptions="bottomGridOptions"
        :headerHeight="0"
        :floatingFiltersHeight="0"
        :columnDefs="columnDefs"
        :rowData="reversesFooter"
        :modules="modules">
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
    </vx-card>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueApexCharts from "vue-apexcharts";
import { AgGridVue } from "@ag-grid-community/vue";
import DatePicker from "vuejs-datepicker";
import reportModule from "@/store/report/moduleReport.js";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import * as lang from 'vuejs-datepicker/src/locale';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

export default {
  data() {
    return {
      from: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      to: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      languages: lang,

      //Table
      searchQuery: "",
      gridOptions: {
        alignedGrids: [],
        suppressHorizontalScroll: true
      },
      modules: AllCommunityModules,

      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Id Reversa",
          field: "id_reverse",
          width: 120,
          filter: true,
        },
        {
          headerName: "Turno",
          field: "id_sale_init",
          width: 120,
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Fecha",
          field: "date_reverse",
          width: 150,
          filter: true,
          comparator: this.myDateComparator,
        },
        {
          headerName: "Tipo de Venta",
          field: "sale_type_description",
          width: 150,
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Total",
          field: "sale_total",
          width: 150,
          filter: true,
          valueFormatter: params => this.$options.filters.currencySign(params.data.sale_total, '$ '),
        },
        {
          headerName: "Cajero",
          field: "seller",
          width: 280,
          filter: true,
          floatingFilter: true
        },
        {
          headerName: "Gerente",
          field: "id_account_manager",
          width: 280,
          filter: true,
          floatingFilter: true
        },
      ],
      reverses: [],
      reversesFooter: [],
      bottomGridOptions: {
        alignedGrids: [],
        defaultColDef: {
          editable: false,
          sortable: true,
          resizable: true,
          filter: true,
          flex: 1,
          minWidth: 100,
          floatingFiltersHeight: 0
        }
      },
    };
  },
  components: {
    VuePerfectScrollbar,
    VueApexCharts,
    AgGridVue,
    DatePicker,
  },

  created() {
    //Modules
    if (!reportModule.isRegistered) {
      this.$store.registerModule("reportModule", reportModule);
      reportModule.isRegistered = true;
    }

    this.loadReverseTable();
  },

  methods: {
    isDate(val) {
      return val instanceof Date && !isNaN(val.valueOf())
    },
    loadReverseTable() {
      this.reverses = []
      this.reversesFooter = []

      var dates = {from: this.isDate(this.from)?this.from.toISOString().split('T')[0] : '', to:this.isDate(this.to)?this.to.toISOString().split('T')[0] : ''};
      this.$store
        .dispatch("reportModule/getReverse", {id:this.$store.state.AppActiveUser.institution.id, dates:dates})
        .then((response) => {
          if (response.data.reverses.length > 0) {
            let s = JSON.parse(JSON.stringify(response.data.reverses));
            let s_footer = {};
            s_footer.id_reverse = "Total";
            s_footer.sale_total = 0;

            s.forEach((element) => {
              element.date_reverse = new Date(element.date_reverse).toLocaleDateString("es-AR", this.$localeDateOptions);
              response.data.staff.forEach((staff) => {
                if (element.seller == staff.id_account) {
                  element.seller = staff.name + " " + staff.last_name;
                }
                if (element.id_account_manager == staff.id_account) {
                  element.id_account_manager = staff.name + " " + staff.last_name;
                }
              })
              s_footer.sale_total += element.sale_total;
            });

            this.reversesFooter = [s_footer]
            this.reverses = s;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    myDateComparator(date1, date2) {
      var date1Number = this.monthToComparableNumber(date1);
      var date2Number = this.monthToComparableNumber(date2);
      if (date1Number === null && date2Number === null) {
        return 0;
      }
      if (date1Number === null) {
        return -1;
      }
      if (date2Number === null) {
        return 1;
      }
      return date1Number - date2Number;
    },
    monthToComparableNumber(date) {
      if (date === undefined || date === null || date.length !== 10) {
        return null;
      }
      var yearNumber = date.substring(6, 10);
      var monthNumber = date.substring(3, 5);
      var dayNumber = date.substring(0, 2);
      var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
      return result;
    },
    onFirstDataRendered: function() {
      this.gridColumnApi.autoSizeAllColumns();
    }
  },

  watch: {
     from: function () {
          this.loadReverseTable();
      },
          to: function () {
          this.loadReverseTable();
      },
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 7;
        // this.gridOptions.columnApi.setColumnPinned('email', null)
      }
      //   else this.gridOptions.columnApi.setColumnPinned('email', 'left')
    },
  },

  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  beforeMount() {
    this.gridOptions.alignedGrids.push(this.bottomGridOptions);
    this.bottomGridOptions.alignedGrids.push(this.gridOptions);
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
};
</script>

<style>
  .ag-cell{
    text-transform: capitalize;
  }
  .ag-grid-table.total .ag-row {
    /* background-color: #7c71f138; */
    font-weight: 700;
  }
  .ag-grid-table.total .ag-header {
    border: none;
  }
</style>
